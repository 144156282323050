// # Area school districts
// CCA = "Clear Creek Amana Community School District"

// # Private schools
// WILLOWWIND = "Willowwind School"
// SCATTERGOOD = "Scattergood Friends School"
// HILLCREST = "Hillcrest Academy"
// REGINA = "Regina Catholic Education Center"
// HERITAGE = "Heritage Christian School"
// MONTESSORI = "Montessori School of Iowa City"

// Map of API string to display name for school districts
export const SCHOOL_DISTRICTS = {
  iccsd: "Iowa City Community School District",
}
