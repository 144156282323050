export const Footer = () => (
  <>
    <footer className="mt-24 footer px-20 py-10 bg-base-200 text-base-content">
      <div>
        <span className="footer-title">Catalog</span>
        <a className="link link-hover" href="/">
          Camps
        </a>
        <a className="link link-hover" href="/providers">
          Providers
        </a>
      </div>
      <div>
        <span className="footer-title">Company</span>
        <a className="link link-hover" href="/about">
          About us
        </a>
      </div>
      <div>
        <span className="footer-title">Legal</span>
        <a className="link link-hover" href="/disclaimers">
          Disclaimers
        </a>
      </div>
      <hr />
    </footer>
    <footer className="footer border-t px-20 pt-4 pb-10 bg-base-200 text-gray-500 text-sm justify-center">
      Corridor Camps is not affiliated with the camp providers listed in our
      catalog and does not endorse or make any claims about the suitability of
      any particular camp for your family.
    </footer>
  </>
)
