// There are warnings about not passing strings to the Date constructor and not using Date.parse,
// but when using date-only strings the result will consistently be a UTC date.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#date_time_string_format
export const formatDateRange = (startDateStr, endDateStr) => {
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)

  // Date range has the format:
  // <start month> <start date> - <end month> <end date>, <end year>

  // Special case: start date === end date, only the "end" portion is displayed
  // Careful to triple-equals-check only on the date string, not the date objects
  if (startDateStr === endDateStr) {
    return endDate.toLocaleDateString("en-us", {
      day: "numeric",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    })
  }

  // Special case: start month === end month, <end month> is omitted
  const startMonth = startDate.toLocaleDateString("en-us", {
    month: "short",
    timeZone: "UTC",
  })
  const endMonth = endDate.toLocaleDateString("en-us", {
    month: "short",
    timeZone: "UTC",
  })
  if (startMonth === endMonth) {
    return `${startMonth} ${startDate.getUTCDate()} - ${endDate.getUTCDate()}, ${endDate.getUTCFullYear()}`
  }

  // Default case, full date range (year is assumed to be the same)
  return `${startMonth} ${startDate.getUTCDate()} - ${endMonth} ${endDate.getUTCDate()}, ${endDate.getUTCFullYear()}`
}

export const formatTimeRange = (startTimeStr, endTimeStr) => {
  // Times are UTC (single Z) because we want to render independently of browser locale
  const startTime = new Date(`2022-03-01T${startTimeStr}.000Z`)
  const endTime = new Date(`2022-03-01T${endTimeStr}.000Z`)

  const timeFormat = { hour: "numeric", minute: "2-digit", timeZone: "UTC" }

  return `${startTime.toLocaleTimeString(
    "en-us",
    timeFormat
  )} - ${endTime.toLocaleTimeString("en-us", timeFormat)}`
}
