import create from "zustand"
import { devtools } from "zustand/middleware"

export const useStore = create(
  devtools((set) => ({
    apiTokenKey: null,
    setApiTokenKey: (key) => set((state) => ({ apiTokenKey: key })),

    logout: () => set((state) => ({ apiTokenKey: null })),
  }))
)
