import { useEffect, useMemo, useRef, useState } from "react"
import cx from "classnames"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"

export const SearchFilter = ({
  className,
  organizations,
  setQueryField,
  setAgeField,
  setOrganizationsField,
}) => {
  const [expanded, setExpanded] = useState(false)

  const [ageFilterEnabled, setAgeFilterEnabled] = useState(false)
  // Track age on the slider so we can show it in text
  const sliderMinAge = 3
  const sliderMaxAge = 18
  // Make sure the default age matches that in `Search` form
  const [age, setAge] = useState(11)

  // Track whether each organization is checked or unchecked,
  // default to all checked
  const [organizationStatus, setOrganizationStatus] = useState(
    // Map each ID to true, e.g. {1: true, 2: true, ...}
    Object.fromEntries(organizations.map((org) => [org.id, true]))
  )
  const keywordRef = useRef()
  const [filtersEnabled, setFiltersEnabled] = useState(false)

  const sortedOrganizations = useMemo(() => {
    return _.sortBy(organizations, (org) => org.name)
  }, [organizations])

  const handleAgeChanged = (age) => {
    setAge(age)
    setAgeField(age)
  }

  const handleOrganizationClicked = (event) => {
    // Toggle checked status
    setOrganizationStatus({
      ...organizationStatus,
      [event.target.id]: !organizationStatus[event.target.id],
    })
    evaluateFiltersEnabled()
  }

  const selectAllOrganizations = () => {
    setOrganizationStatus(
      Object.fromEntries(organizations.map((org) => [org.id, true]))
    )
    evaluateFiltersEnabled()
  }

  const selectNoneOrganizations = () => {
    setOrganizationStatus(
      Object.fromEntries(organizations.map((org) => [org.id, false]))
    )
    evaluateFiltersEnabled()
  }

  const setKeywords = (text) => {
    setQueryField(text)
    evaluateFiltersEnabled()
  }

  const evaluateFiltersEnabled = () => {
    const numCheckedOrgs = Object.values(organizationStatus).reduce(
      (a, b) => a + (b ? 1 : 0),
      0
    )
    setFiltersEnabled(
      keywordRef.current?.value?.trim() ||
        ageFilterEnabled ||
        numCheckedOrgs < organizations.length
    )
  }

  useEffect(() => {
    if (ageFilterEnabled) {
      setAgeField(age)
    } else {
      setAgeField("")
    }
    evaluateFiltersEnabled()
  }, [age, ageFilterEnabled, evaluateFiltersEnabled])

  useEffect(() => {
    // Update form field when `organizationStatus` changes (when boxes are clicked)
    const checkedOrganizations = Object.entries(organizationStatus)
      .filter(([orgId, checked]) => checked)
      .map(([orgId, checked]) => orgId)

    setOrganizationsField(checkedOrganizations.join(","))
    evaluateFiltersEnabled()
  }, [organizationStatus, setOrganizationsField, evaluateFiltersEnabled])

  return (
    <div className={cx(className, { "mb-12": expanded })}>
      <div className="indicator">
        {filtersEnabled && (
          <span className="indicator-item badge border-secondary-dark badge-secondary"></span>
        )}
        <button
          type="button"
          className="btn btn-xs mt-1"
          onClick={() => setExpanded(!expanded)}
        >
          <span className="mr-1">{expanded ? "Hide" : "Show"} filters</span>
          <FontAwesomeIcon
            icon={expanded ? solid("chevron-down") : solid("chevron-right")}
          />
        </button>
      </div>
      <div id="filters" className={cx({ hidden: !expanded })}>
        <div className="mt-2 mx-12 text-sm space-y-6">
          <div className="flex items-center">
            Keywords:{" "}
            <input
              ref={keywordRef}
              type="text"
              className="ml-2 flex-grow input border-primary-dark text-lg"
              maxLength={100}
              onChange={(event) => setKeywords(event.target.value)}
            />
          </div>
          {/* Age filter */}
          <div>
            <div className="flex items-center">
              <label className="flex items-center cursor-pointer">
                <input
                  className="checkbox checkbox-xs mr-2"
                  type="checkbox"
                  onChange={() => setAgeFilterEnabled(!ageFilterEnabled)}
                />
                <span className={cx({ "text-gray-400": !ageFilterEnabled })}>
                  Age:
                </span>
              </label>
              <div className="ml-6 w-64">
                <input
                  className={cx("w-full h-1.5 appearance-none cursor-pointer", {
                    "bg-slate-300": ageFilterEnabled,
                    "bg-gray-200": !ageFilterEnabled,
                  })}
                  type="range"
                  defaultValue={age}
                  min={sliderMinAge}
                  max={sliderMaxAge}
                  onChange={(event) => handleAgeChanged(event.target.value)}
                  disabled={!ageFilterEnabled}
                />
              </div>
              <div className="ml-6  text-lg">
                {ageFilterEnabled ? age : "All ages"}
              </div>
            </div>
            {ageFilterEnabled && (
              <div className="ml-20 mt-2 italic">
                *Some providers may accept children just outside their posted
                age range, so we'll show you camps for ages {parseInt(age) - 1},{" "}
                {age}, and {parseInt(age) + 1}.
              </div>
            )}
          </div>
          <div className="flex items-top">
            <div>Providers:</div>
            <div className="ml-4 flex-grow space-y-1.5">
              <div className="space-x-4">
                <button
                  type="button"
                  className="link"
                  onClick={selectAllOrganizations}
                >
                  All
                </button>
                <button
                  type="button"
                  className="link"
                  onClick={selectNoneOrganizations}
                >
                  None
                </button>
              </div>
              {sortedOrganizations.map((org) => (
                <div key={org.id}>
                  <label className="flex items-center cursor-pointer">
                    <input
                      className="checkbox checkbox-xs"
                      type="checkbox"
                      id={org.id}
                      checked={organizationStatus[org.id]}
                      onChange={handleOrganizationClicked}
                    />
                    <span className="ml-1">{org.name}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
