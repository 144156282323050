import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"

import icon from "../images/logo_sun_secondary_dark.png"

const BANNER_STORAGE_KEY = "banner-dismissed"

// Set `BANNER_ID` to a unique ID for the notification to be shown.
// Using YYYY-MM-DD is a good practice.
// E.g.
// const BANNER_ID = "2023-01-06"
// const BANNER_TEXT = "Summer camp listings are now available!"
// Set BANNER_ID and BANNER_TEXT to null to delete the 'banner-dismissed' local storage item
const BANNER_ID = null
const BANNER_TEXT = null

export const Banner = ({ className }) => {
  const [showBanner, setShowBanner] = useState(false)
  useEffect(() => {
    const bannerDismissed = window.localStorage.getItem(BANNER_STORAGE_KEY)
    if (BANNER_ID === null) {
      window.localStorage.removeItem(BANNER_STORAGE_KEY)
    } else if (bannerDismissed !== BANNER_ID) {
      setShowBanner(true)
    }
  }, [])

  const dismiss = () => {
    window.localStorage.setItem(BANNER_STORAGE_KEY, BANNER_ID)
    setShowBanner(false)
  }

  return showBanner && BANNER_ID && BANNER_TEXT ? (
    <div className={className}>
      <div className="flex flex-row items-center gap-4 bg-secondary-light font-bold p-8">
        <img className="w-8" src={icon} />
        <div>{BANNER_TEXT}</div>
        <button className="flex-1 text-right" onClick={dismiss}>
          <FontAwesomeIcon icon={solid("x")} />
        </button>
      </div>
    </div>
  ) : null
}
