import { NavLink, Link } from "react-router-dom"
import { useLogout, useUser } from "../api/hooks"
import logo from "../images/logo.svg"
import { Avatar } from "./Avatar"

const StyledNavLink = ({ children, to }) => {
  const activeClassName = "text-secondary border-b border-secondary"
  return (
    <NavLink
      to={to}
      className={({ isActive }) => (isActive ? activeClassName : undefined)}
    >
      {children}
    </NavLink>
  )
}

export const Navbar = () => {
  const { data: user } = useUser()
  const { mutateAsync: logout } = useLogout()

  // !!user is true if user is logged in
  const loginOption = !!user
    ? {
        label: "Log out",
        onClick: logout,
      }
    : { label: "Log in" }

  return (
    <div className="">
      {/* This logo is _above_ the navbar, shown only in mobile view to give the navbar
      links enough room. */}
      <div className="flex-1 md:hidden">
        <Link to="/">
          <img className="w-48" src={logo} />
        </Link>
      </div>
      <div className="navbar bg-base-100">
        <div className="navbar-start">
          {/* This logo is _in_ the navbar, shown only in md+ view where the navbar
      links have enough room. */}
          <div className="hidden md:block">
            <Link to="/">
              <img className="w-48" src={logo} />
            </Link>
          </div>
        </div>
        <div className="navbar-end uppercase text-sm space-x-4">
          <StyledNavLink to="/">Camps</StyledNavLink>
          <StyledNavLink to="/providers">Providers</StyledNavLink>
          <StyledNavLink to="/about">About</StyledNavLink>
          {/* <div className="dropdown dropdown-end">
          <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
            <div className="w-10 md:w-12 rounded-xl">
              <Avatar />
            </div>
          </label>
          <ul
            tabIndex="0"
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-36"
          >
            <li>
              <a>{loginOption.label}</a>
            </li>
          </ul>
        </div> */}
        </div>
      </div>
    </div>
  )
}
