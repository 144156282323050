import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import cx from "classnames"
import { useState } from "react"

/* Pagination bar looks like:

  |<  <  N-maxButtonsPerSide ...  N-1  N  N+1 ... N+maxButtonsPerSide  >  >|

  where N is the current page of results
  |< and >| go to first and last pages respectively
*/
export const PaginationBar = ({
  offset,
  count,
  previous,
  next,
  setOffset,
  limit,
  maxButtonsPerSide = 1,
}) => {
  const numPages = Math.ceil(count / limit)
  const currentPage = Math.floor(offset / limit) + 1

  let buttons
  const numButtons = maxButtonsPerSide * 2 + 1
  // Initialize with the first `numButtons` numbers on the pagination butons
  // https://stackoverflow.com/questions/3895478/does-javascript-have-a-method-like-range-to-generate-a-range-within-the-supp
  buttons = Array(numButtons)
    .fill(1)
    .map((x, y) => x + y)

  if (numPages > numButtons) {
    // There are more pages than we can display at once. If we're passed the midpoint of the
    // base range (e.g. past 3 in a range of 1-5), shift the pagination numbers accordingly.
    const midpoint = Math.ceil(numButtons / 2)
    if (currentPage > midpoint) {
      buttons.forEach((_, i, a) => (a[i] += currentPage - midpoint))
    }
  } else {
    // We can display all pages at once
    buttons = Array(numPages)
      .fill(1)
      .map((x, y) => x + y)
  }

  const handlePageClick = (page) => {
    if (page !== currentPage) {
      setOffset((page - 1) * limit)
    }
  }

  const handleFirstClick = () => {
    setOffset(0)
  }

  const handleLastClick = () => {
    setOffset((numPages - 1) * limit)
  }

  const handlePrevNextClick = (url) => {
    const params = new URL(url).searchParams
    setOffset(params.get("offset"))
  }

  return (
    <div className="mt-2 flex items-center space-x-2">
      <button
        className={cx("btn", "btn-xs", { "btn-disabled": currentPage === 1 })}
        onClick={() => handleFirstClick(next)}
      >
        | <FontAwesomeIcon icon={solid("chevron-left")} />
      </button>
      <button
        className={cx("btn", "btn-sm", { "btn-disabled": !previous })}
        onClick={() => handlePrevNextClick(previous)}
      >
        <FontAwesomeIcon icon={solid("chevron-left")} />
      </button>
      {buttons.map((page) => (
        <div key={page}>
          <button
            className={cx("btn", "btn-circle", "btn-md", {
              "btn-secondary": page === currentPage,
              "btn-disabled": page > numPages,
            })}
            onClick={() => handlePageClick(page)}
          >
            {page}
          </button>
        </div>
      ))}
      <button
        className={cx("btn", "btn-sm", { "btn-disabled": !next })}
        onClick={() => handlePrevNextClick(next)}
      >
        <FontAwesomeIcon icon={solid("chevron-right")} />
      </button>
      <button
        className={cx("btn", "btn-xs", {
          "btn-disabled": currentPage === numPages,
        })}
        onClick={() => handleLastClick()}
      >
        <FontAwesomeIcon icon={solid("chevron-right")} /> |
      </button>
    </div>
  )
}
