import { useEffect, useRef } from "react"
import { Error } from "./Error"
import { CampSearchResult } from "./CampSearchResult"
import { PaginationBar } from "./PaginationBar"
import { LoadingSpinner } from "./LoadingSpinner"

export const SearchResults = ({
  className,
  limit,
  setOffset,
  data,
  status,
  scrollResultsIntoView,
}) => {
  // Use a ref for the element to scroll into view after changing pages
  const postPaginationRef = useRef()

  // Whenever the search result status changes, scroll the postPaginationRef element into view
  useEffect(() => {
    if (scrollResultsIntoView && postPaginationRef.current) {
      postPaginationRef.current.scrollIntoView()
    }
  }, [status, scrollResultsIntoView])

  let content

  if (status === "loading") {
    content = (
      <LoadingSpinner
        className="w-20 ml-4"
        textClassName="text-xl"
        text="Loading camps..."
      />
    )
  } else if (status === "error") {
    content = <Error />
  } else if (status === "success") {
    if (data.results?.length === 0) {
      content = (
        <p>
          Sorry, we couldn't find any upcoming camps that match your search.
        </p>
      )
    } else {
      const { offset, count, results } = data
      content = (
        <>
          <div className="pt-8" ref={postPaginationRef}>
            {count === 1 ? (
              <>Found just 1 camp</>
            ) : (
              <>
                Showing {offset + 1} - {offset + results.length} of {count}{" "}
                camps
              </>
            )}
          </div>
          <PaginationBar
            {...data}
            setOffset={setOffset}
            limit={limit}
            status={status}
          />
          <div className="space-y-4 p-4">
            {results.map((camp, i) => (
              <div key={i}>
                <CampSearchResult camp={camp} />
              </div>
            ))}
          </div>
          <PaginationBar {...data} setOffset={setOffset} limit={limit} />
        </>
      )
    }
  }

  return <div className={className}>{content}</div>
}
