import logoSun from "../images/logo_sun.png"

export const LoadingSpinner = ({
  className = "w-30 mx-auto",
  textClassName = "text-2xl text-center",
  text,
}) => (
  <div className="w-full">
    <img className={`animate-spin-slow ${className}`} src={logoSun} />
    <div className={`${textClassName} mt-4 text-secondary`}>{text}</div>
  </div>
)
