import { useEffect } from "react"
import ReactGA from "react-ga4"
import { Outlet, useLocation } from "react-router-dom"

import { useUser } from "../api/hooks"
import { Banner } from "./Banner"
import { Navbar } from "./Navbar"
import { Footer } from "./Footer"

export const App = () => {
  const location = useLocation()
  const { isSuccess, data: user } = useUser()

  useEffect(() => {
    ReactGA.send("pageview")
  }, [location])

  return (
    <div className="md:mt-2 ml-4 mr-4">
      <Navbar />
      {/* {isSuccess && <div>Welcome, {user.first_name}!</div>} */}
      <Banner className="mt-4" />
      <div className="container mt-8 mx-auto md:px-4">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
