import { useStore } from "./store"

const API_SERVER = process.env.REACT_APP_API_SERVER
const e = encodeURIComponent

// Get data from API server
const get = async (path, options = {}) => {
  // Add authorization if we have an API token
  const apiTokenKey = useStore.getState().apiTokenKey
  if (apiTokenKey) {
    options.headers = {
      Authorization: `Token ${apiTokenKey}`,
    }
  }

  const response = await fetch(`${API_SERVER}/${path}`, {
    ...options,
    // fetch() won't send cross-origin cookies unless you set the credentials init option
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    credentials: "include",
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  // Read body as JSON
  const data = await response.json()
  return data
}

// Post data to API server
const post = async (path, postData, options = {}) => {
  // Add authorization if we have an API token
  const apiTokenKey = useStore.getState().apiTokenKey
  if (apiTokenKey) {
    options.headers = {
      Authorization: `Token ${apiTokenKey}`,
    }
  }

  const response = await fetch(`${API_SERVER}/${path}`, {
    ...options,
    // fetch() won't send cross-origin cookies unless you set the credentials init option
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    credentials: "include",
    method: "POST",
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }

  // Read response data as JSON
  const data = await response.json()
  return data
}

export const login = async ({ provider, accessToken }) => {
  return post(`dj-rest-auth/${provider}`, { access_token: accessToken })
}

export const logout = async () => {
  return post("dj-rest-auth/logout")
}

export const getUser = async () => {
  return get("api/user")
}

export const getNextCamps = async () => {
  return get("api/next-camps")
}

export const getSearchResults = async ({
  query,
  age,
  organizations,
  startDate,
  endDate,
  offset,
  limit,
}) => {
  return get(
    `api/search?offset=${e(offset)}&limit=${e(limit)}&q=${e(query)}&a=${e(
      age
    )}&o=${e(organizations)}&sd=${e(startDate)}&ed=${e(endDate)}`
  )
}

export const getOrganizations = async () => {
  return get("api/organizations")
}

export const getCamps = async () => {
  return get("api/camps")
}
