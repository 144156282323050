import { useEffect, useRef, useState } from "react"

/**
 *
 * overrideInput: optional function that takes clicked value from the
 *               range and returns value the range slider should move to
 */
export const RangeSlider = ({
  min,
  max,
  initialStartValue,
  initialEndValue,
  sliderColor = "#cbd5e1",
  rangeColor = "#3EABBF",
  overrideInput,
  onChange,
}) => {
  // References to the range <input> elements
  const startRange = useRef(null)
  const endRange = useRef(null)

  // The current positions of the start and end range handles
  const [startValue, setStartValue] = useState(initialStartValue)
  const [endValue, setEndValue] = useState(initialEndValue)

  // Weird that we need this, but when RangeSlider is rerendered by clicking a
  // month on the DateRangeTimeline,
  useEffect(() => {
    setStartValue(initialStartValue)
    setEndValue(initialEndValue)
  }, [initialStartValue, initialEndValue])

  // Reset the range values and color whenever the start and end value handles change
  useEffect(() => {
    // Reset the value on both ranges to prevent default behavior.
    // Otherwise, whichever range received the event will have its
    // handle moved to `clickedValue`.
    startRange.current.value = startValue
    endRange.current.value = endValue

    // Reset the linear gradient between the range handles
    // https://medium.com/@predragdavidovic10/native-dual-range-slider-html-css-javascript-91e778134816
    const rangeDistance = max - min
    startRange.current.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(startValue / rangeDistance) * 100}%,
      ${rangeColor} ${(startValue / rangeDistance) * 100}%,
      ${rangeColor} ${(endValue / rangeDistance) * 100}%, 
      ${sliderColor} ${(endValue / rangeDistance) * 100}%, 
      ${sliderColor} 100%)`

    if (onChange) {
      onChange(startValue, endValue)
    }
  }, [startValue, endValue])

  const onInput = (event) => {
    const input = parseInt(event.target.value)

    // Override input value if necessary
    const clickedValue = overrideInput ? overrideInput(input) : input

    // Apply the new value to the closest handle
    const distanceFromStartHandle = Math.abs(clickedValue - startValue)
    const distanceFromEndHandle = Math.abs(clickedValue - endValue)
    if (distanceFromStartHandle < distanceFromEndHandle) {
      setStartValue(clickedValue)
    } else {
      setEndValue(clickedValue)
    }
  }

  return (
    <div className="relative">
      <input
        className="w-full h-1.5 appearance-none absolute cursor-pointer"
        ref={startRange}
        id="startRange"
        type="range"
        value={startValue}
        min={min}
        max={max}
        onInput={onInput}
      />
      <input
        className="w-full h-1.5 bg-transparent appearance-none absolute cursor-pointer"
        ref={endRange}
        id="endRange"
        type="range"
        value={endValue}
        min={min}
        max={max}
        onInput={onInput}
      />
    </div>
  )
}
