import { Details, Header, KeyInfo } from "."

export const GenericSearchResult = ({ camp }) => {
  return (
    <div className="border-t border-secondary-dark/50 pt-4">
      <Header
        camp={camp}
        registerButtonClassName="bg-register hover:bg-register-hover"
      />
      <KeyInfo camp={camp} />
      <Details camp={camp} />
    </div>
  )
}
