import { useState } from "react"
import { DateTime } from "luxon"
import cx from "classnames"
import { RangeSlider } from "./RangeSlider"

/**
 *
 * onClick is an optional function that takes (startValue, endValue)
 * as a range value when a month is clicked.
 */
const MonthBar = ({ minDate, maxDate, numCampsPerMonth, onClick }) => {
  const MONTH_INDEX_DELIMITER = "-"

  // `minDate` and `maxDate` are luxon DateTimes
  const numMonths = Math.ceil(maxDate.diff(minDate, "months").months)

  // Determine total number of days in month bar. Do this dynamically,
  // versus a static dict lookup of days per month,
  // to be accurate in leap years.
  let totalDays = 0
  let d = minDate
  // monthCellInfo captures [1-based month index, month short name, num days in month]
  let monthCellInfo = []
  for (let i = 0; i < numMonths; i++) {
    totalDays += d.daysInMonth
    monthCellInfo.push({
      monthIndex: `${d.year}${MONTH_INDEX_DELIMITER}${d.month}`,
      monthShortName: d.monthShort,
      numDaysInMonth: d.daysInMonth,
    })
    d = d.plus({ months: 1 })
  }

  const handleMonthClick = (monthIndex) => {
    const [year, month] = monthIndex.split(MONTH_INDEX_DELIMITER)
    const monthStartDate = DateTime.fromObject({
      year,
      month,
      day: 1,
    })
    const monthEndDate = monthStartDate.endOf("month")

    const startValue = Math.max(
      DateTime.now().diff(minDate, "days").days,
      monthStartDate.diff(minDate, "days").days
    )
    const endValue = monthEndDate.diff(minDate, "days").days
    onClick(startValue, endValue)
  }

  return (
    <div className="flex flex-row">
      {monthCellInfo.map(
        ({ monthIndex, monthShortName, numDaysInMonth }, i) => {
          return (
            <button
              className={cx(
                "border",
                "border-y-0",
                "border-slate-300",
                "text-xs",
                "text-center",
                {
                  "border-r-0": i !== monthCellInfo.length - 1,
                },
                "pt-6"
              )}
              style={{ width: `${(numDaysInMonth * 100) / totalDays}%` }}
              key={monthShortName}
              onClick={() => handleMonthClick(monthIndex)}
            >
              <div className="uppercase">{monthShortName}</div>
              <div className="mt-2 text-primary-dark flex flex-col  md:flex-row md:items-center md:justify-center">
                <div className="md:mr-1">
                  {numCampsPerMonth[monthIndex] || "0"}
                </div>
                <div className="text-2xs md:text-xs">
                  {numCampsPerMonth[monthIndex] === 1 ? "camp" : "camps"}
                </div>
              </div>
            </button>
          )
        }
      )}
    </div>
  )
}

export const DateRangeTimeline = ({
  className,
  maxDateYYYYMMDD, // rightmost date on the timeline, should be the last day of a month
  initialStartDateYYYYMMDD,
  initialEndDateYYYYMMDD,
  numCampsPerMonth,
  setFormField,
}) => {
  // Set up luxon DateTimes
  const today = DateTime.now()
  const minDate = today.startOf("month")
  let maxDate = DateTime.fromISO(maxDateYYYYMMDD)
  const initialStartDate = DateTime.fromISO(initialStartDateYYYYMMDD)
  const initialEndDate = DateTime.fromISO(initialEndDateYYYYMMDD)

  // Make sure `maxDate` includes end date
  if (initialEndDate > maxDate) {
    maxDate = initialEndDate
  }

  // Get integer values for initial start and end dates
  const initialStartValue = initialStartDate.diff(minDate, "days").days
  const initialEndValue = initialEndDate.diff(minDate, "days").days
  const todayValue = today.diff(minDate, "days").days

  // `min` value is always 0 and represents today,
  // so `max` value is the number of days we're showing in
  // the timeline and represents that many days into the future
  const min = 0
  const max = maxDate.diff(minDate, "days").days

  // The current positions of the start and end range handles
  const [startValue, setStartValue] = useState(initialStartValue)
  const [endValue, setEndValue] = useState(initialEndValue)

  const onSliderChange = (start, end) => {
    setStartValue(start)
    setEndValue(end)

    // Pass the selected dates to the parent state
    if (start !== initialStartValue) {
      setFormField(
        "startDate",
        minDate.plus({ days: start }).toFormat("yyyy-MM-dd")
      )
    }
    if (end !== initialEndValue) {
      setFormField(
        "endDate",
        minDate.plus({ days: end }).toFormat("yyyy-MM-dd")
      )
    }
  }

  return (
    <div className={className}>
      <div className="text-center text-xl mb-6">
        {minDate.plus({ days: startValue }).toFormat("MMM d, yyyy")} -{" "}
        {minDate.plus({ days: endValue }).toFormat("MMM d, yyyy")}
      </div>
      <RangeSlider
        min={min}
        max={max}
        initialStartValue={startValue}
        initialEndValue={endValue}
        overrideInput={(clickedValue) => Math.max(todayValue, clickedValue)}
        onChange={onSliderChange}
      />
      <MonthBar
        minDate={minDate}
        maxDate={maxDate}
        numCampsPerMonth={numCampsPerMonth}
        onClick={onSliderChange}
      />
    </div>
  )
}
