import { useState } from "react"
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"

import { useNextCamps, useSearch } from "../api/hooks"
import { SearchResults } from "./SearchResults"
import { SCHOOL_DISTRICTS } from "../api/constants"
import { LoadingSpinner } from "./LoadingSpinner"
import { DateRangeTimeline } from "./DateRangeTimeline"
import { SearchFilter } from "./SearchFilter"

export const Search = () => {
  const { data: initialSearchData, status: initialSearchStatus } = useNextCamps(
    {
      onSuccess: (data) => {
        // Populate the form data with these dates
        setValue("startDate", data.start_date)
        setValue("endDate", data.end_date)

        // Set state for number of camps per month
        setNumCampsPerMonth(data.num_camps)

        // REMOVE (HOTFIX)
        // Set the form values to enable pagination. This incurs an extra API call on page load,
        // so get rid of it! And the second call is related to the next hotfix below.
        setFormValues(getValues())
        setInitialDates({
          startDate: data.start_date,
          endDate: data.end_date,
        })

        setOrganizations(data.organizations)
      },
    }
  )

  // In all of the state defined below, initial data that depends on the `useNextCamps`
  // query result must be initialized to the results from `initialSearchData`. This object
  // will be empty on initial site visit, but subsequently will be populated for when
  // the user naviates to e.g. the About page, then back to Search

  // This state makes it possible to display "Here are the dates...",
  // and also is used for the form's default values (which are in turn used
  // to detect when fields are dirty)
  const [initialDates, setInitialDates] = useState({
    startDate: initialSearchData?.start_date,
    endDate: initialSearchData?.end_date,
  })
  const [organizations, setOrganizations] = useState(
    initialSearchData?.organizations
  )

  // Track whether user initiated a query, to be able to differentiate those results
  // versus initial "next camps" results on initial page load
  const [querySubmitted, setQuerySubmitted] = useState(false)
  const [numCampsPerMonth, setNumCampsPerMonth] = useState(
    initialSearchData?.num_camps
  )

  const [formValues, setFormValues] = useState({})
  const [limit, setLimit] = useState(10)
  // Pagination works by calling `setOffset` with a new value, which will retrigger the `useSearch` query below
  const [offset, _setOffset] = useState(0)
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm(
    // Pass default values so we can track when the form is dirty
    {
      defaultValues: {
        startDate: initialDates.startDate,
        endDate: initialDates.endDate,
        query: "",
        age: "",
        organizations: "",
      },
    }
  )
  const { data: searchData, status: searchStatus } = useSearch({
    formValues,
    offset,
    limit,
    config: {
      enabled: querySubmitted,
      staleTime: 1000 * 60 * 60, // Search results become stale after 1 hour
    },
  })

  // Proxy function for react hook form's `setValue` which will ensure
  // the field is marked dirty
  const setFormValue = (fieldName, fieldValue) => {
    setValue(fieldName, fieldValue, { shouldDirty: true })
  }

  // setOffset is used by the PaginationBar
  const setOffset = (arg) => {
    _setOffset(arg)

    // Paginating triggers a new query
    setQuerySubmitted(true)
  }

  const onSubmit = async ({ query, startDate, endDate }) => {
    // Reset offset
    setOffset(0)

    // Set state with the form values to trigger react query
    setFormValues(getValues())

    // Track state when user-initiated query is triggered
    setQuerySubmitted(true)

    // Reset form dirty state
    reset({ keepValues: true })
  }

  return (
    <div>
      <div className="space-y-12 text-xl  md:max-w-2xl md:mx-auto">
        {numCampsPerMonth && (
          <div className="text-center">
            <div className="text-4xl text-primary-dark">
              {Object.values(numCampsPerMonth).reduce(
                (partialSum, numCampsInMonth) => partialSum + numCampsInMonth,
                0
              )}
            </div>
            <div className="text-xs">upcoming camps</div>
          </div>
        )}
        <div>
          Our camp directory is regularly updated as local camp providers post
          their schedules. If you don't find the perfect camp today, be sure to
          check back later!
        </div>
      </div>

      {/* Consistent spacer above loading spinner or search content */}
      <div className="mt-20"></div>

      {initialSearchStatus === "success" ? (
        <div>
          {getValues("startDate") === initialDates.startDate &&
            getValues("endDate") === initialDates.endDate &&
            initialSearchStatus === "success" &&
            initialSearchData.count > 0 && (
              <div className="text-xl  md:max-w-2xl md:mx-auto">
                Here are the dates and camps for the next school break for{" "}
                <span>
                  {SCHOOL_DISTRICTS[initialSearchData?.school_district]}
                </span>
                .
              </div>
            )}

          <form className="mb-16" onSubmit={handleSubmit(onSubmit)}>
            <input {...register("startDate")} type="hidden" />{" "}
            <input {...register("endDate")} type="hidden" />
            {/* `maxDateYYYYMMDD` should match views.NextCampsView.MAX_DATE */}
            <DateRangeTimeline
              className="my-12"
              maxDateYYYYMMDD="2025-08-01"
              initialStartDateYYYYMMDD={initialDates.startDate}
              initialEndDateYYYYMMDD={initialDates.endDate}
              numCampsPerMonth={numCampsPerMonth}
              setFormField={setFormValue}
            />
            <input {...register("query")} type="hidden" />
            <input {...register("age")} type="hidden" />
            <input {...register("organizations")} type="hidden" />
            <SearchFilter
              className=""
              organizations={organizations}
              setQueryField={(value) => setFormValue("query", value)}
              setAgeField={(value) => {
                setFormValue("age", value)
              }}
              setOrganizationsField={(value) =>
                setFormValue("organizations", value)
              }
            />
            <div className="flex">
              <button
                className="mx-auto btn btn-primary rounded-lg"
                disabled={!isDirty}
              >
                {" "}
                <FontAwesomeIcon
                  className="mr-2"
                  icon={solid("magnifying-glass")}
                />{" "}
                Find camps
              </button>
            </div>
          </form>

          <SearchResults
            className="mt-8"
            limit={limit}
            setOffset={setOffset}
            // When the page loads, `searchData` and `searchStatus` are undefined because
            // the user hasn't submitted a search query yet. In that case, we display
            // the initial search results from next-camps.
            data={querySubmitted ? searchData : initialSearchData}
            status={querySubmitted ? searchStatus : initialSearchStatus}
            scrollResultsIntoView={querySubmitted}
          />
        </div>
      ) : (
        <LoadingSpinner text="Looking for camps..." />
      )}
    </div>
  )
}
