import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { useState } from "react"

import { formatDateRange, formatTimeRange } from "../../utils"
import { Days } from "../Days"
import { ActivenetSearchResult } from "./ActivenetSearchResult"
import { GenericSearchResult } from "./GenericSearchResult"

export const ORG_NAME_TO_LOGO_CLASSNAME = {
  "becky's mindful kitchen": ["bg-logo-bmk", "w-16", "h-9"],
  "belin-blank center": ["bg-logo-belin-blank", "w-16", "h-12", "bg-black"],
  "big picture gallery and studio": ["bg-logo-big-picture", "w-16", "h-9"],
  "broadway bootcamp": ["bg-logo-broadway-bootcamp", "w-16", "h-16"],
  "brush and barrel": ["bg-logo-brush-and-barrel", "w-16", "h-16"],
  "camp tanager": ["bg-logo-tanager", "w-16", "h-16"],
  "coralville parks and recreation department": [
    "bg-logo-coralville-parks-rec",
    "w-16",
    "h-9",
  ],
  "country camp": ["bg-logo-country-camp", "w-32", "h-16"],
  filmscene: ["bg-logo-filmscene", "w-16", "h-16"],
  "hawkeye area council, boy scouts of america": [
    "bg-logo-hac-bsa",
    "w-32",
    "h-9",
    "bg-hac-bsa",
  ],
  "hillcrest academy": ["bg-logo-hillcrest", "w-16", "h-16"],
  "icor boxing": ["bg-logo-icor", "w-16", "h-16"],
  "indian creek nature center": ["bg-logo-indian-creek", "w-16", "h-9"],
  "iowa city fab lab": ["bg-logo-icfablab", "w-16", "h-9"],
  "iowa city kickers": ["bg-logo-ickickers", "w-16", "h-16"],
  "iowa city parks and recreation department": [
    "bg-logo-iowa-city-parks-rec",
    "w-16",
    "h-16",
  ],
  "iowa city robotics": ["bg-logo-iowa-city-robotics", "w-16", "h-9"],
  "iowa city west high school": ["bg-logo-iowa-city-west", "w-16", "h-16"],
  "iowa gym-nest": ["bg-logo-ign", "w-16", "h-9", "bg-black"],
  "iowa soccer club": ["bg-logo-iowa-soccer-club", "w-16", "h-16"],
  "kirkwood community college": ["bg-logo-kirkwood", "w-16", "h-9"],
  "mercy iowa city": ["bg-logo-mercy-ic", "w-16", "h-16"],
  "nolte academy": ["bg-logo-nolte", "w-16", "h-12"],
  "north liberty recreation department": [
    "bg-logo-north-liberty-recreation",
    "w-16",
    "h-9",
  ],
  "scattergood friends school": [
    "bg-logo-scattergood",
    "w-16",
    "h-9",
    "px-1",
    "bg-scattergood",
  ],
  "steam on": ["bg-logo-steam-on", "w-16", "h-9"],
  "taproot nature experience": ["bg-logo-taproot", "w-16", "h-16"],
  "the dance academy of cedar rapids": [
    "bg-logo-dacr",
    "w-16",
    "h-9",
    "bg-black",
  ],
  "the iowa children's museum": ["bg-logo-icm", "w-16", "h-9"],
  "the rink at coral ridge": ["bg-logo-rink", "w-16", "h-9"],
  "the university of iowa recreational services": [
    "bg-logo-uiowa",
    "w-16",
    "h-9",
    "bg-black",
  ],
  "the university of iowa school of music": [
    "bg-logo-uiowa",
    "w-16",
    "h-9",
    "bg-black",
  ],
  "ui wild (wildlife instruction and leadership development)": [
    "bg-logo-uiowa",
    "w-16",
    "h-9",
    "bg-black",
  ],
  "willowwind school": ["bg-logo-willowwind", "w-16", "h-9"],
  "ymca of the cedar rapids metro area": ["bg-logo-ymca", "w-16", "h-9"],
}

const RegisterButton = ({ camp, className }) => (
  <a
    href={camp.url}
    rel="noopener noreferrer"
    target="_blank"
    className={cx(className, "bg-no-repeat w-30 h-9")}
  />
)

export const Header = ({ camp, registerButtonClassName }) => (
  <div className="space-y-4">
    <div className="flex flex-row items-center uppercase text-sm space-x-2">
      <div
        className={cx(
          "bg-contain",
          "bg-no-repeat",
          "bg-center",
          "bg-origin-content",
          ORG_NAME_TO_LOGO_CLASSNAME[camp.organization.name.toLowerCase()]
        )}
      />
      <div>{camp.organization.name}</div>
    </div>
    <div className="flex flex-row items-start space-x-6">
      <div className="flex-1 text-xl font-bold">{camp.name}</div>
      {camp.url && (
        <RegisterButton camp={camp} className={registerButtonClassName} />
      )}
    </div>
  </div>
)

export const Details = ({ camp, className }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      <button
        type="button"
        className={cx("btn", "cursor-pointer", { "mb-2": !expanded })}
        onClick={() => setExpanded(!expanded)}
      >
        Details{" "}
        <FontAwesomeIcon
          className="ml-1 text-sm"
          icon={expanded ? solid("chevron-down") : solid("chevron-right")}
        />
      </button>
      {expanded && (
        <div
          className={cx(className, "md:px-4 py-4")}
          dangerouslySetInnerHTML={{ __html: camp.description }}
        />
      )}
    </>
  )
}

export const KeyInfoPiece = ({ title, content }) => (
  <div className="flex-auto pt-4 mr-2">
    <h4 className="text-xs font-bold uppercase">{title}</h4>
    {content}
  </div>
)

export const KeyInfo = ({ camp }) => {
  const ages =
    !!camp.min_age && !!camp.max_age
      ? `${camp.min_age} - ${camp.max_age}`
      : !!camp.min_age
      ? `${camp.min_age} and older`
      : !!camp.max_age
      ? `Up to ${camp.max_age}`
      : null

  const cities = new Set(camp.places.map((place) => place.city))
  const location = [...cities].sort().join(",")

  return (
    <div className="rounded-lg bg-base-200 px-6 space-y-6 mt-6 mb-4 pb-4">
      <div className="flex flex-row flex-wrap md:px-12 lg:px-4">
        <KeyInfoPiece
          title="Dates"
          content={formatDateRange(
            camp.schedule.start_date,
            camp.schedule.end_date
          )}
        />
        <KeyInfoPiece
          title="Days"
          content={<Days days={camp.schedule.days} />}
        />
        {camp.schedule.start_time && (
          <KeyInfoPiece
            title="Time"
            content={formatTimeRange(
              camp.schedule.start_time,
              camp.schedule.end_time
            )}
          />
        )}
        {ages && <KeyInfoPiece title="Ages" content={ages} />}
        {location && <KeyInfoPiece title="Location" content={location} />}
      </div>
    </div>
  )
}

export const CampSearchResult = ({ camp }) =>
  camp.organization.source === "activenet" ? (
    <ActivenetSearchResult camp={camp} />
  ) : (
    <GenericSearchResult camp={camp} />
  )
