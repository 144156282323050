import ducks from "../images/ducks.jpg"

export const About = () => (
  <div className="space-y-8">
    <h1 className="text-4xl font-bold">About us</h1>
    <img alt="parent duck with ducklings in tow" src={ducks} />
    <p>
      At Corridor Camps, our mission is to make it easy for Iowa City area
      parents to find camps that fit their schedule.
    </p>
    <p>
      Our catalog features hundreds of camps from local providers in Iowa City,
      Coralville, North Liberty, and beyond; covering school breaks in spring,
      summer, fall, and winter; with activities ranging from art and theater, to
      computer programming and robotics, to fishing and archery.
    </p>
    <p>
      Corridor Camps was founded by local parents tired of spending hours trying
      to track multiple websites and signups when it came time to plan their
      kids' activities for the summer. We aim to save you that time by being a
      one-stop site for all your camp scheduling needs.
    </p>
  </div>
)
