import React from "react"

import logo from "../images/logo.svg"
import { Error } from "./Error"

// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="md:mt-2 ml-4 mr-2">
          <img className="w-48 mb-12" src={logo} />
          <Error content="Something went wrong. Please try refreshing this page in your browser." />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
