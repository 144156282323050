import React from "react"
import { render } from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import ReactGA from "react-ga4"

import "./styles/index.css"
import { App } from "./components/App"
import { NotFound } from "./components/NotFound"
import { About } from "./routes/about"
import { Search } from "./components/Search"
import { Login } from "./components/Login"
import { Disclaimer } from "./routes/disclaimers"
import ErrorBoundary from "./components/ErrorBoundary"
import { Providers } from "./routes/providers"

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="/" element={<Search />} />
              <Route path="about" element={<About />} />
              <Route path="disclaimers" element={<Disclaimer />} />
              <Route path="providers" element={<Providers />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
