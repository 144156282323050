import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  getCamps,
  getNextCamps,
  getOrganizations,
  getSearchResults,
  getUser,
  login,
  logout,
} from "."
import { useStore } from "./store"

export const useLogin = () => {
  return useMutation(login)
}

export const useLogout = () => {
  const stateLogout = useStore((state) => state.logout)
  const queryClient = useQueryClient()

  return useMutation(async () => {
    // To prevent race conditions, await the result of the logout to prevent
    // re-querying for /user/ while we're still logged in
    await logout()
    stateLogout()
    // Reset here, not invalidate, because we don't need to refetch
    queryClient.resetQueries("user")
  })
}

export const useUser = () => {
  return useQuery("user", getUser, { retry: false })
}

export const useNextCamps = (options) => {
  return useQuery("next-camps", getNextCamps, options)
}

export const useSearch = ({ formValues, offset, limit, config }) => {
  const { query: rawQuery, age, organizations, startDate, endDate } = formValues
  // Clean up empty spaces in raw query
  const query = rawQuery
    ? rawQuery
        .split(" ")
        .filter((term) => !!term)
        .join(" ")
        .trim()
    : ""

  return useQuery(
    ["search", query, age, organizations, startDate, endDate, offset, limit],
    () =>
      getSearchResults({
        query,
        age,
        organizations,
        startDate,
        endDate,
        offset,
        limit,
      }),
    config
  )
}

export const useOrganizations = () => {
  return useQuery("organizations", getOrganizations)
}

export const useCamps = () => {
  return useQuery("camps", getCamps)
}
