import { useMemo } from "react"
import _ from "lodash"
import { useOrganizations } from "../api/hooks"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"

import { LoadingSpinner } from "../components/LoadingSpinner"
import { ORG_NAME_TO_LOGO_CLASSNAME } from "../components/CampSearchResult"

const ProviderEntry = ({ name, url, description }) => (
  <div className="flex flex-row items-center text-xl border-t border-secondary-dark/50 pt-4">
    <div
      className={cx(
        "bg-contain",
        "bg-no-repeat",
        "bg-center",
        "bg-origin-content",
        ORG_NAME_TO_LOGO_CLASSNAME[name.toLowerCase()],
        "mr-4"
      )}
    ></div>
    <div>
      <div>{name}</div>
      <a
        className="link text-sm"
        rel="noopener noreferrer"
        target="_blank"
        href={url}
      >
        {url}
      </a>
    </div>
  </div>
)

export const Providers = () => {
  const { data, status } = useOrganizations()

  const sortedOrganizations = useMemo(() => {
    return _.sortBy(data, (org) => org.name)
  }, [data])

  return (
    <div className="space-y-8">
      <h1 className="text-4xl font-bold">Camp Providers</h1>
      <div className="flex items-center alert rounded-lg bg-secondary-light px-8 lg:w-2/3">
        <FontAwesomeIcon icon={solid("circle-question")} />
        <div className="block space-y-4">
          {" "}
          <p>
            Are you a camp provider who would like to be added to our catalog,
            or an existing provider who would like to reach out about your
            listings?
          </p>
          <p>
            Contact us at{" "}
            <a className="link" href="mailto:team@corridorcamps.com">
              team@corridorcamps.com
            </a>
          </p>
        </div>
      </div>
      {status === "success" ? (
        sortedOrganizations.map((org) => (
          <ProviderEntry key={org.id} {...org} />
        ))
      ) : (
        <LoadingSpinner text="Loading providers..." />
      )}
    </div>
  )
}
