import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"

export const Error = ({
  content = "Something went wrong. Please try again.",
}) => (
  <div className="flex items-center alert alert-error rounded-lg">
    <div>
      <FontAwesomeIcon icon={solid("exclamation-circle")} />
      {content}
    </div>
  </div>
)
